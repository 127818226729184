<template>
  <div
    :class="[
      'coupon-face',
      {
        'ship-coupon-face': isShipCoupon
      }
    ]"
  >
    <div class="coupon-text">
      <s-adapter-text
        v-if="isShipCoupon"
        class="ship-coupon-value"
        min-size="10"
        :text="couponTitle"
      />
      <p 
        v-else
        class="discount-value"
        :style="fontStyle"
      >
        {{ couponTitle }}
      </p>
      <s-adapter-text
        class="subtitle"
        min-size="10"
        :text="threshold"
      />
    </div>
    <div 
      v-if="upLabel" 
      class="up-to"
    >
      {{ upLabel }}
    </div> 
  </div>
</template>

<script>
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CouponFace',
  components: {
    SAdapterText,
  },
  props: {
    couponTitle: { // 券面额
      type: String,
      default: '',
    },
    threshold: { // 券门槛
      type: String,
      default: '',
    },
    upLabel: {
      type: String,
      default: '',
    },
    isShipCoupon: {
      type: Boolean,
      default: false,
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    }
  },
})
</script>

<style lang="less" scoped>
.coupon-face {
  position: relative;
  width: 154/37.5rem;
  height: 100%;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/pwa_dist/images/add-on/addon_coupon-1b1feae6ae.png');
    background-size: contain;
    transform: rotateY(0deg) /* rtl: rotateY(180deg) */;;
  }
  .coupon-text {
    position: absolute;
    text-align: center;
    width: 51%;
    top: 30/37.5rem;
    left: 40/37.5rem;
    height: 34/37.5rem;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .discount-value {
      width: 100%;
      font-weight: bold;
      line-height: 22/37.5rem;
      color: #DD3D39;
      text-shadow: 1/37.5rem 0 #FFE86C, -1/37.5rem 0 #FFE86C, 0 1/37.5rem #FFE86C, 0 -1/37.5rem #FFE86C,
        0px 2/37.5rem 2/37.5rem #DDB05A;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .ship-coupon-value {
      width: 100%;
      font-weight: bold;
      line-height: 22/37.5rem;
      color: #198055;
      text-shadow: 0px 1px 1px rgba(221, 176, 90, 0.80);
      -webkit-text-stroke-width: 1;
      -webkit-text-stroke-color: #FFF3B8;
    }
    .subtitle {
      line-height: 12/37.5rem;
      color: #F44424;
      .font-dpr(24px);
    }
  }
  .up-to {
    .font-dpr(18px);
    position: absolute;
    font-weight: bold;
    color: #fff;
    top: 19/37.5rem;
    right: 22/37.5rem;
    border-top-left-radius: 2/37.5rem;
    border-bottom-left-radius: 2/37.5rem;
    padding: 0rem 2/37.5rem;
    background:  linear-gradient(270deg, #FFA41B 0%, #FFBD14 100%);
    &::after {
      content: ' ';
      position: absolute;
      bottom: -4.9/37.5rem;
      right: 2.2/37.5rem;
      border-top: 4/37.5rem solid transparent;
      border-bottom: 4/37.5rem solid transparent;
      transform: rotate(45deg);
      border-right: 4/37.5rem solid #9B4D00;
    }
  }
}
.ship-coupon-face {
  &::before {
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/10/21/0b/1729492444e75e05a4921ad28aba85825261a44f2f.png');
  }
  .coupon-text {
    left: 33/37.5rem;
    width: 56%;
    .subtitle {
      color: #198055;
    }
    .ship-coupon-value {
      text-shadow: none;
    }
  }
  .up-to {
    background: linear-gradient(270deg, #FFA41B 0%, #FFBD14 100%);
    right: 25/37.5rem;
    &::after {
      border-right: 4/37.5rem solid rgba(155, 77, 0, 1);
    }
  }
}
</style>
