<template>
  <div class="count-down">
    <span>{{ endTip }}</span>
    <span class="time-text">
      {{ countTimer.timeObj.H }}:{{ countTimer.timeObj.M }}:{{ countTimer.timeObj.S }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { CountDown } from '@shein/common-function'

export default defineComponent({
  props: {
    timeStamp: { // 秒
      type: Number,
      required: false,
      default: 0,
    },
    isDayMode: {
      type: Boolean,
      default: false
    },
    endTip: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      countTimer: new CountDown(),
    }
  },
  mounted() {
    this.initCountDown()
  },
  methods: {
    // 初始化计时器
    initCountDown() {
      if (!(this.timeStamp)) return
      this.countTimer.start({
        timestamp: this.timeStamp,
        dayMode: this.isDayMode,
        endFunc: () => {},
      })
    },
  },
})
</script>

<style lang="less" scoped>
.count-down {
  position: relative;
  height: 48px;
  width: fit-content;
  color: #fff;
  text-align: right;
  font-size: 12px;
  padding: 3px 6px 3px 36px;
  display: inline-flex;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotateY(0deg) /* rtl: rotateY(180deg) */;;
    background-image: url('/pwa_dist/images/add-on/rectangle-eb2aae18cf.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  span {
    position: relative;
  }
  .time-text {
    font-weight: bold;
    padding-left: 3px;
  }
}
</style>
