<template>
  <div class="coupon-one">
    <div class="coupon-text">
      <p 
        class="discount-value"
        :style="fontStyle"
      >
        {{ title }}
      </p>
    </div>
    <div class="bottom-tips">
      <OverText @overed="overed">
        <span v-if="!isOvered">{{ desc }}</span>
        <span 
          v-else 
          class="brand"
        >
          SHEIN
        </span>
      </OverText>
    </div>
    <div 
      v-if="label" 
      class="up-to"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
export default defineComponent({
  name: 'CouponOneNewUser',
  components: { 
    OverText: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/OverText.vue')),
  },
  props: {
    title: { // 券面额
      type: String,
      default: '',
    },
    desc: { // 券门槛
      type: String,
      default: '',
    },
    label: {
      type: Boolean,
      default: false,
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOvered: false,
    }
  },
  methods: {
    overed(bool) {
      this.isOvered = bool
    }
  },
})
</script>

<style lang="less" scoped>
.coupon-one {
  position: relative;
  padding: 38/37.5rem 46/37.5rem 25/37.5rem 27/37.5rem;
  width: 157/37.5rem;
  height: 100%;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/03/28/d8/17116157665fb1653f1f8b80bc5f73d562d605df8b.png') /* rtl: url('https://img.ltwebstatic.com/images3_ccc/2024/03/28/b9/17116109347e8ce95602b24bba156692efb8bfea2f.png') */;
  }
  .coupon-text {
    width: 100%;
    text-align: center;
    position: relative;
    .discount-value {
      font-weight: bold;
      font-size: 18/37.5rem;
      line-height: 22/37.5rem;
      color: #5E2B0E;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .bottom-tips {
    margin-top: 4/37.5rem;
    width: 100%;
    text-align: center;
    position: relative;
    line-height: 14/37.5rem;
    color: #B2614A;
    font-size: 12/37.5rem;
    .brand {
      font-size: 13/37.5rem;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      text-transform: capitalize;
      color: #B2614A;
    }
  }
  .up-to {
    .font-dpr(16px);
    position: absolute;
    font-weight: bold;
    color: #fff;
    top: 23/37.5rem;
    right: 29/37.5rem;
    padding: 1px 4px;
    border-radius: 8px 8px 8px 1px;
    border: 0.5px solid #FFF;
    background: linear-gradient(217deg, #FFDEB7 1.06%, #FFBB6C 20.53%, #F57600 59.42%, #FF9559 88.52%);
  }
}
</style>
