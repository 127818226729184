<template>
  <div class="full-discount">
    <ShrinkText
      class="tip"
      :content="tip"
      :max-width="68"
    />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
export default defineComponent({
  name: 'FullDiscount',
  components: {
    ShrinkText: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/ShrinkText.vue')),
  },
  props: {
    tip: {
      type: String,
      default: '',
    },
  }
})
</script>

<style lang="less" scoped>
.full-discount {
  position: relative;
  height: 100%;
  width: 302/75rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/pwa_dist/images/add-on/promo-discount-coin-8d32a6bac1.png');
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
  }
  .tip {
    position: absolute;
    color: #fff;
    font-family: SF Pro;
    font-weight: 860;
    top: 82/75rem;
    left: 68/75rem;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 1;
    // -webkit-text-stroke: 1px #DDB05A;
    span {
      direction: ltr /*rtl:ltr*/;
    }
  }
}
</style>
