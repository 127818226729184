<template>
  <div class="coupon-more">
    <div class="coupon-text">
      <p 
        class="discount-value"
        :style="fontStyle"
      >
        {{ title }}
      </p>
    </div>
    <div class="bottom-tips">
      {{ desc }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CouponMore',
  props: {
    title: { // 券面额
      type: String,
      default: '',
    },
    desc: { // 券门槛
      type: String,
      default: '',
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style lang="less" scoped>
.coupon-more {
  position: relative;
  width: 124/37.5rem;
  height: 100%;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transform: rotateY(0deg) /* rtl: rotateY(180deg) */;;
    background-image: url('http://img.ltwebstatic.com/images3_acp/2024/10/28/d5/173009838326aba1b234a78417942d78e6b5fdfa49.png');
  }
  .coupon-text {
    width: 54/37.5rem;
    text-align: center;
    position: absolute;
    top: 26/37.5rem;
    left: 31/37.5rem;
    .discount-value {
      font-weight: bold;
      font-size: 16px;
      line-height: 22/37.5rem;
      color: #DD3D39;
      text-shadow: 1/37.5rem 0 #FFE86C, -1/37.5rem 0 #FFE86C, 0 1/37.5rem #FFE86C, 0 -1/37.5rem #FFE86C,
        0px 2/37.5rem 2/37.5rem #DDB05A;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .bottom-tips {
    width: 60/37.5rem;
    text-align: center;
    position: absolute;
    bottom: 28/37.5rem;
    left: 28/37.5rem;
    line-height: 12/37.5rem;
    color: #FAD8A2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .font-dpr(22px);
  }
}
</style>
