<template>
  <div class="coupon-one">
    <div class="coupon-text">
      <p 
        class="discount-value"
        :style="fontStyle"
      >
        {{ title }}
      </p>
    </div>
    <div class="bottom-tips">
      <OverText @overed="overed">
        <span v-if="!isOvered">{{ desc }}</span>
        <span 
          v-else 
          class="brand"
        >
          SHEIN
        </span>
      </OverText>
    </div>
    <div 
      v-if="label" 
      class="up-to"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
export default defineComponent({
  name: 'CouponOne',
  components: { 
    OverText: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/OverText.vue')),
  },
  props: {
    title: { // 券面额
      type: String,
      default: '',
    },
    desc: { // 券门槛
      type: String,
      default: '',
    },
    label: {
      type: Boolean,
      default: false,
    },
    fontStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOvered: false,
    }
  },
  methods: {
    overed(bool) {
      this.isOvered = bool
    }
  },
})
</script>

<style lang="less" scoped>
.coupon-one {
  position: relative;
  width: 114/37.5rem;
  height: 100%;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    transform: rotateY(0deg) /* rtl: rotateY(180deg) */;;
    background-image: url('/pwa_dist/images/add-on/addon_coupon_single-6ea9894f53.png');
  }
  .coupon-text {
    width: 58/37.5rem;
    text-align: center;
    position: absolute;
    top: 28/37.5rem;
    left: 28/37.5rem;
    .discount-value {
      font-weight: bold;
      font-size: 16px;
      line-height: 22/37.5rem;
      color: #DD3D39;
      text-shadow: 1/37.5rem 0 #FFE86C, -1/37.5rem 0 #FFE86C, 0 1/37.5rem #FFE86C, 0 -1/37.5rem #FFE86C,
        0px 2/37.5rem 2/37.5rem #DDB05A;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .bottom-tips {
    width: 60/37.5rem;
    text-align: center;
    position: absolute;
    bottom: 20/37.5rem;
    left: 26/37.5rem;
    line-height: 17/37.5rem;
    color: #FAD8A2;
    .font-dpr(22px);
    .brand {
      .font-dpr(26px);
      text-align: center;
      font-style: normal;
      font-weight: bold;
      text-transform: capitalize;
      color: #C70600;
    }
  }
  .up-to {
    .font-dpr(20px);
    position: absolute;
    font-weight: bold;
    color: #fff;
    top: 12/37.5rem;
    right: 20/37.5rem;
    border-top-left-radius: 2/37.5rem;
    border-bottom-left-radius: 2/37.5rem;
    padding: 0rem 2/37.5rem;
    background:  linear-gradient(270deg, #FFA41B 0%, #FFBD14 100%);
    &::after {
      content: ' ';
      position: absolute;
      bottom: -4.9/37.5rem;
      right: 2.2/37.5rem;
      border-top: 4/37.5rem solid transparent;
      border-bottom: 4/37.5rem solid transparent;
      transform: rotate(45deg);
      border-right: 4/37.5rem solid #9B4D00;
    }
  }
}
</style>
